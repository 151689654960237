@keyframes sail {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(-29.2px);
    }
    100% {
        transform: translateX(0px);
    }
}