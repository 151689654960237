@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(./Montserrat-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Montserrat Medium'), local('Montserrat-Medium'), url(./Montserrat-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(./Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-display: swap;
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url(./Montserrat-Bold.ttf) format('truetype');
}