.itemHovered {
    animation: bounce 0.35s ease infinite alternate;
    margin-left: -13px;
    margin-top: -10px;
}

@keyframes bounce {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-20px);
    }
}
