.bm-menu-wrap {
    height: auto !important;
    top: 65px;
}

.bm-menu {
    background: #122941;
    padding: 32px;
    font-size: 1.15em;
    width: 100%;
}

.bm-item {
    display: inline-block;
    text-align: left;
    text-decoration: none;
    margin-bottom: 10px;
    color: white;
    outline: none;
}

.bm-item svg {
    vertical-align: middle;
    color: #d0d4d9;
}

.bm-item span {
    vertical-align: middle;
}
